import React from "react"
import $ from "jquery"
import { StaticQuery, graphql } from "gatsby"
import { FaTimesCircle, FaEnvelope, FaPhone } from 'react-icons/fa'
import Image from "gatsby-image"

const FormMessage = props => {
  let message = 'Thank you for your submission, we will be with you shortly.';
  let style = {};
  if(props.submitted){
    message = 'Thank you for your submission, we will be with you shortly.';
    style.display = 'block';
  }
  return (
    <p className="form-message" style={style}>
      {message}
      <FaEnvelope />
    </p>
  );
};


export default class ContactForm extends React.Component {
  
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.handleInputChange = this.handleInputChange.bind(this);
    this.changeActive = this.changeActive.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    
    if(typeof window !== 'undefined') {
      var queryString = window.location.search;
      var urlParams = new URLSearchParams(queryString);
      var gclid = urlParams.get('gclid')
    }

    if(typeof document !== 'undefined'){
      var phone = document.getElementById("number_rewrite").innerHTML;
      var vsref = phone.replace(/-/g, "");
    }
    
    this.state = {
      name: "",
      honeypot: "",
      tel: "",
      message: "",
      vsref: vsref,
      gclid: gclid,
      submitted: false,
      formAction: 'https://metrics.vitalstorm.com/email_form_submission/283ddd75-2a82-47c6-ab62-c35002864828/'
    };
  }
  handleInputChange(event){
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }
  changeActive(event){
    $(".form").toggleClass("expanded");
    $("body").toggleClass("formExpanded");

  }
  handleSubmit(event){
    event.preventDefault();
    //phone number validation
    var phoneno = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    var phoneinput = document.getElementById('mail-tel');
    
    //email validation
    const emailvalidation = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    var emailinput = document.getElementById('mail-email');
    var emailinputValue = emailinput.value;
    
    var emailerrormsg = document.createElement("p");
    emailerrormsg.classList.add('form-error-text');
    emailerrormsg.innerHTML = "Wrong Email Format, please check and fix before submitting";

    function emailIsValid (email) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    }

    if (/*phoneinput.value.match(phoneno) &&*/ emailIsValid(emailinputValue)){
      const elements = document.getElementsByClassName('form-error-text-shown');

      emailinput.classList.remove('form-error'); 
      // emailerrormsg.classList.remove('form-error-text-shown');
      while(elements.length > 0){
        elements[0].parentNode.removeChild(elements[0]);
      }
      const fieldData = new URLSearchParams({
        name: this.state.name,
        honeypot: this.state.honeypot,
        message: this.state.message,
        tel: this.state.tel,
        // city: this.state.city,
        // zipcode: this.state.zipcode,
        vsref: this.state.vsref,
        gclid: this.state.gclid,
        });
        $.ajax({
          type: "POST",
           url: this.state.formAction,
           data:  fieldData.toString(), // serializes the form's elements.
           success: data => {
              this.setState({
                name: "",
                honeypot: "",
                tel: "",
                // city: "",
                // zipcode: "",
                message: "",
                vsref: "",
                gclid: "",
                submitted: true
              });
           }
          })
    } else if (emailIsValid(emailinputValue) == false){
      emailinput.parentNode.insertBefore(emailerrormsg, emailinput.nextSibling);
      emailinput.classList.add('form-error');
      emailerrormsg.classList.add('form-error-text-shown');
      emailinput.focus();
      console.log('wrong email');
    }    
  }  
   render() {
    
    return (
      <>
      <StaticQuery
          query={graphql`
              query formquery {
                sanityCompanyInfo {
                  companyname
                  phone
                  companyTagline
                  logoWhite {
                    asset {
                      fluid{
                        ...GatsbySanityImageFluid
                        src
                      }
                    }
                  }
                  primarycolor{
                      hex
                  }
                  secondarycolor{
                      hex
                  }
                  accentcolor{
                      hex
                  }
                  formhash
              }
              }
            `}
                 render={data => (
                   <>
                   <div className="popupForm">
                   <div className="form">
                      <div className="two_columns">
                        <div className="column1" style={{backgroundColor: data.sanityCompanyInfo.primarycolor.hex }}>
                        <Image location=""
                            fluid={data.sanityCompanyInfo.logoWhite.asset.fluid}
                            style={{ height: "170px", width: "300px",  }}
                            className="align-center formLogo"
                            alt="Logo"
                          />
                          <p className="tagline">{data.sanityCompanyInfo.companyTagline}</p>
                          <a href={"tel:" + data.sanityCompanyInfo.phone} className="formPhone"><FaPhone /> {data.sanityCompanyInfo.phone}</a>
                        </div>
                        <div className="column2">
                          <div className="innerColumn">
                            <h2>Schedule Service</h2>  
                            <p>Fill out the form below and we'll reach out to schedule your service appointment. </p>
                            <span className="closeForm" onClick={this.changeActive} style={{fill: "#fff"}}><FaTimesCircle /></span>
                            {/* <form ref={this.formRef} id="form-metrics" onSubmit={this.handleSubmit} action={this.state.formAction} method="POST"> */}
                            <form ref={this.formRef} id="form-metrics" onSubmit={this.handleSubmit} action={"https://metrics.vitalstorm.com/email_form_submission/" + data.sanityCompanyInfo.formhash} method="POST">
                              <input id="mail-name" className="inputfield" type="text" name="name" value={this.state.name} onChange={this.handleInputChange} placeholder="Enter your full name" required />
                              <input id="mail-email" className="inputfield" type="text" name="honeypot" value={this.state.honeypot} onChange={this.handleInputChange} placeholder="Email address" minLength="3" maxLength="64" required />
                              <input id="mail-honey" className="inputfield" type="text" name="email" />
                              <input id="mail-tel" className="inputfield" name="tel" value={this.state.tel} pattern="^\d{10}$" type="tel" onChange={this.handleInputChange} placeholder="Phone Number (Format: 1234567890)" required />
                              <input id="mail-message" className="inputfield" type="text" value={this.state.message} onChange={this.handleInputChange} name="message" placeholder="Request a service" required />
                              <input type="hidden" name="gclid" value={this.state.gclid} />
                              <input type="hidden" name="vsref" value={this.state.vsref} />
                              <span class="agreecheckbox">
                              <input type="checkbox" name="checkboxagree" required id="" />
                              <label htmlFor="checkboxagree" id="checkboxagree">By providing your phone number and “clicking the box” you agree to receive marketing calls from FPL Home Services to the number you provided, including such calls sent through an automatic telephone dialing system or using a pre-recorded or artificial voice. This constitutes your consent to receive such calls for a period of one month from the date of this consent notwithstanding any prior registration of your phone number on any state, federal, or internal “Do Not Call” list. Your consent is not a condition of purchasing any property, goods, or services. Depending on your phone plan, standard messaging or other charges may apply. You may opt-out of receiving further calls by contacting us at 833-568-5140.</label>
                            </span>
                              <div className="ajax-button">
                                  <button id="mail-submit" type="submit" name="mail-submit" style={{backgroundColor: data.sanityCompanyInfo.primarycolor.hex}}>Send Request</button>
                              </div>
                              <FormMessage submitted={this.state.submitted} />
                            </form>
                            </div>
                        </div>
                      </div>
                    </div>
                    </div>
                    </>
                  )}  
              />
      </>
    )
  }
}
